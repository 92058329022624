import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Col, Row } from "react-bootstrap"
import ResumeModal from "./ResumeModal"
import { cloudinaryOptimize } from "../../utils"
import { dateAgo } from "../../utils"

export const profSummary = (
  <>
    <Col xs="12" className="mx-1">
      <p>
        Web developer with a background in designing, deploying, and maintaining
        web applications and infrastructures. That's {dateAgo("2019-03-01")},
        and then some!
      </p>
      <p>
        Specialized in client-side development, ensuring optimal accessibility,
        performance, and user-centric outcomes. Proven proficiency in steering
        groups, evaluating project specs, and propelling organizational
        objectives.
      </p>
    </Col>
  </>
)

export const techSkills = (
  <>
    <Col xs="12" className="mx-1 resume-content">
      <dl>
        <dt>Coding skills &amp; tools</dt>
        <dd>
          Mastery in HTML, CSS &amp; its preprocessor SASS, the JavaScript and
          TypeScript, data formats like JSON &amp; YAML, as well as Markdown,
          pattern matching via REGEX, database management with mySQL, and
          scripting with PHP &amp; TWIG
        </dd>
        <dt>JavaScript libraries &amp; integration</dt>
        <dd>
          Proficient in leveraging tools like React, Redux, Node, and other
          specialized libraries including FontAwesome, GreenSock (GSAP), D3, and
          Bootstrap among others
        </dd>
        <dt>Front-end proficiencies</dt>
        <dd>
          Skilled in deploying tools such as Gatsby, Next.js, image management
          with Cloudinary, site deployment with Netlify &amp; its CMS,
          application infrastructure &amp; authentication with Firebase, and
          task automation using Gulp &amp; Webpack
        </dd>
        <dt>Data visualization</dt>
        <dd>
          Comfortable in the use of data visualization and table manipulation
          libraries to output dynamic, interactive data visualizations in web
          browsers. Such libraries include D3.js, Recharts.js, Chart.js,
          DataTables.js, etc.{" "}
          <a
            href="https://codepen.io/collection/WvgaLP"
            target="_blank"
            rel="noreferrer"
          >
            View a collection of my D3 projects here
          </a>
          .
        </dd>
        <dt>Drupal capabilities</dt>
        <dd>
          Adept understanding of Drupal (version 8 onwards) with expertise in
          site design and customization, theme development for content and admin
          interfaces, entity management (like nodes, taxonomies, views), and
          user permission settings. Additionally, competent in the creation of
          custom modules, expanding the functionalities of Drupal's core and
          leveraging Drupal's API through specialized hooks
        </dd>
        <dt>Development setup</dt>
        <dd>
          Habitually work within a Docker container setup, with ability to tweak
          scripts based on project requirements, such as adjusting entry point
        </dd>
        <dt>Collaboration &amp; versioning</dt>
        <dd>
          Active contributor to collaborative code repositories, employing the
          principles of git workflows. Familiar with platforms like GitHub,
          GitLab, and Microsoft's Azure DevOps solution
        </dd>
        <dt>Canadian web standards &amp; design</dt>
        <dd>
          Adept in designing web solutions in line with the WET4 standard and
          ensuring compliance with the Canada.ca content and design guidelines
          to guarantee seamless, accessible, and user-friendly web interfaces
        </dd>
        <dt>MS Office suite &amp; scripting</dt>
        <dd>
          Extensive familiarity with the MS Office tools (including Word, Excel,
          Outlook, SharePoint, PowerPoint) as well as writing tailored macros
          via VBA scripting
        </dd>
        <dt>Accessibility principles</dt>
        <dd>
          Solid foundation in ensuring web compliance as per WCAG/a11y
          standards, ensuring consistent validation and incorporating
          internationalization best practices
        </dd>
        <dt>Code standards</dt>
        <dd>
          Passionate about producing clean, annotated and readable code,
          ensuring web applications and code bases of high caliber
        </dd>
      </dl>
    </Col>
  </>
)

export const genSkills = (
  <>
    <Col xs="12" className="mx-1 resume-content">
      <dl>
        <dt>Languages</dt>
        <dd>Fluent in both English and French (oral and written)</dd>
        <dt>Teamwork</dt>
        <dd>
          High interpersonal and team building skills; autonomous when working
          solo or collaboratively
        </dd>
        <dt>Project management</dt>
        <dd>
          Result-oriented with a proven track record in planning and executing
          projects in high-pressure environments
        </dd>
        <dt>Data analysis &amp; presentation</dt>
        <dd>
          Aptitude to synthesize, cross-reference data, and effectively convey
          analysis results; ability to propose strategies based on industry best
          practices
        </dd>
        <dt>Remote work</dt>
        <dd>Reliable, punctual, and fully equipped for remote work</dd>
      </dl>
    </Col>
  </>
)

const workData = [
  {
    employer: "Royal Canadian Mounted Police",
    title: "Web developer",
    department: "Strategic Planning & Policy Directorate",
    location: "Ottawa, ON",
    to: "Present",
    from: "Aug 2022",
    body: (
      <Col xs="12" className="mx-1 resume-content">
        <p>
          Role changed in order to leverage my technical knowledge and to
          broaden my experience.
        </p>
        <ul>
          <li>
            Developed and sustained web applications, focusing on both frontend
            experience and backend functionality
          </li>
          <li>
            Executed custom CMS integrations in Drupal 9, harnessing specific
            Drupal hooks and modules to fulfill organizational objectives
          </li>
          <li>
            Designed custom CKEditor 5 plugins to augment content management
            capabilities
          </li>
          <li>
            Collaborated with divisional stakeholders to craft bespoke
            solutions, including the creation of specialized applications for
            job poster processing and display
          </li>
          <li>
            Engineered solutions for data retrieval, processing, and
            presentation, such as converting CSV data into interactive data
            tables
          </li>
          <li>
            Utilized JavaScript and PHP libraries, harnessing their APIs for
            enhanced data visualization, including data tables and SVG graphs
          </li>
          <li>Crafted HTML templates to streamline team productivity</li>
          <li>
            Revitalized both new and legacy websites, addressing functionality
            issues or refreshing outdated designs
          </li>
          <li>
            Assessed project requirements, strategized optimal approaches within
            constraints, and communicated analytical findings to leadership and
            stakeholders
          </li>
        </ul>
      </Col>
    )
  },
  {
    employer: "Royal Canadian Mounted Police",
    title: "Acting senior web administrator",
    department: "Strategic Planning & Policy Directorate",
    location: "Ottawa, ON",
    to: "Aug 2022",
    from: "Jun 2022",
    body: (
      <Col xs="12" className="mx-1 resume-content">
        <p>
          Moved to the senior level to my web administrator position in response
          to the added responsibilities and scope of my role.
        </p>
        <ul>
          <li>
            Provided technical guidance to divisional publishers and
            communication strategists
          </li>
          <li>
            Analyzed request requirements, offering informed recommendations to
            both management and senior leadership
          </li>
          <li>
            Led and executed projects, addressing unique challenges with
            innovative solutions while maintaining prompt delivery
          </li>
          <li>
            Authored and updated team technical documents, including style
            guides
          </li>
          <li>
            Managed and allocated tasks to team members from a centralized
            request handling system
          </li>
        </ul>
      </Col>
    )
  },

  {
    employer: "Royal Canadian Mounted Police",
    title: "Web administrator",
    department: "Strategic Planning & Policy Directorate",
    location: "Ottawa, ON",
    to: "Jun 2022",
    from: "Jan 2021",
    body: (
      <Col xs="12" className="mx-1 resume-content">
        <p>
          Responsible for publishing web content found on the RCMP's internal
          facing sites as well as its external facing sites, such as:
        </p>
        <ul>
          <li>
            <a
              href="https://www.rcmp-grc.gc.ca/en"
              target="_blank"
              rel="noopener noreferrer"
            >
              Royal Canadian Mounted Police (rcmp-grc.gc.ca)
            </a>
          </li>
          <li>
            <a
              href="https://www.antifraudcentre-centreantifraude.ca/index-eng.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              Canadian Anti-Fraud Centre (antifraudcentre-centreantifraude.ca)
            </a>
          </li>
          <li>
            <a
              href="https://cisc-scrc.gc.ca/index-eng.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              Criminal Intelligence Service Canada (cisc-scrc.gc.ca)
            </a>
          </li>
          <li>
            <a
              href="https://canadasmissing.ca/index-eng.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              Canada's Missing (canadasmissing.ca)
            </a>
          </li>
          <li>
            <a
              href="https://www.cpc-ccp.gc.ca/index-eng.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              Canadian Police College (cpc-ccp.gc.ca)
            </a>
          </li>
          <li>
            <a
              href="https://www.cpic-cipc.ca/index-eng.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              Canadian Police Information Centre (cpic-cipc.ca)
            </a>
          </li>
        </ul>
        <p>
          Responsible for applying the{" "}
          <i>
            Policy on Communications and Federal Identity of the Government of
            Canada
          </i>{" "}
          and the{" "}
          <i>
            Treasury Board Secretariat&rsquo;s Standard on Web Accessibility
            including WCAG 2.0 AA
          </i>{" "}
          to all published content.
        </p>
        <p>
          Regularly required to liaise with Communication Strategists and their
          client stakeholders in order to prepare content for web deployment.
        </p>
        <p>
          Regularly implement creative solutions for unique requirements while
          remaining with RCMP core style and accessibility guidelines.
        </p>
        <p>
          Occasionally liaise and assist with DevOps team operations to develop
          front end deliverables using HTML, CSS and JavaScript, using offline
          Apache server tools (XAMPP).
        </p>
      </Col>
    )
  }
]

export const renderWorkExperience = () => {
  return workData.map(
    ({ employer, location, from, to, title, department, body }, i) => {
      return (
        <Col key={i} xs="12" className="mb-3">
          <article>
            <Row className="resume-work align-items-start mb-0 px-1 resume-content">
              <Col xs="9" md="10">
                <h3 className="mb-0">{title}</h3>
                <p className="subtitle-resume mb-1">{`${department} - ${employer}`}</p>
              </Col>
              <Col xs="3" md="2" className="align-self-end">
                <p className="text-end dates mb-1">
                  {location}
                  <br />
                  {to}
                  <br />
                  {from}
                </p>
              </Col>
            </Row>
            <hr class="mt-0" />
            <Row>
              <Col>{body}</Col>
            </Row>
          </article>
        </Col>
      )
    }
  )
}

export const Certifications = () => {
  const resumeQuery = useStaticQuery(
    graphql`
      query {
        allCloudinaryMedia(
          filter: { public_id: { glob: "eric-alain/resume/*" } }
        ) {
          nodes {
            secure_url
          }
        }
      }
    `
  )
  const images = resumeQuery.allCloudinaryMedia.nodes

  const [modalData, setModalData] = useState({
    /*sample: {
      show: false,
      title: "",
      link: ""
    }*/
  })

  const handleClose = title => {
    setModalData({ ...modalData, [title]: { show: false } })
  }

  const certificationData = [
    {
      title: "Full-Stack Engineer - Professional Certification",
      issuer: "Codecademy",
      issueDate: "Jan 2025",
      expiryDate: "No expiration date",
      link: "https://www.codecademy.com/profiles/Ericalain1/certificates/ffd0f42cce1a44e9a0108b365047a0a6"
    },
    {
      title: "Microsoft Certified - Azure Fundamentals (AZ-900)",
      issuer: "Microsoft",
      issueDate: "Aug 2024",
      expiryDate: "No expiration date",
      link: "https://learn.microsoft.com/api/credentials/share/en-us/EricAlain-4417/9FB9D5D6E601FC7?sharingId=ED621108D74FB558"
    },
    {
      title: "Learn PHP Course",
      issuer: "Codecademy",
      issueDate: "Nov 2023",
      expiryDate: "No expiration date",
      link: "https://www.codecademy.com/profiles/Ericalain1/certificates/d24ce3aa4ed99ac04afffec4a78e2e44"
    },
    {
      title: "Advanced React and GraphQl",
      issuer: "wesBos",
      issueDate: "Nov 2023",
      expiryDate: "No expiration date",
      link: "https://res.cloudinary.com/dky5oko1h/image/upload/v1659653641/eric-alain/resume/argql-certificate.jpg"
    },
    {
      title: "Learn TypeScript Course",
      issuer: "Codecademy",
      issueDate: "Jul 2023",
      expiryDate: "No expiration date",
      link: "https://www.codecademy.com/profiles/Ericalain1/certificates/56fb1e71303e37b643bb1905f31c8a09"
    },
    {
      title: "Front-End Engineer - Professional Certification",
      issuer: "Codecademy",
      issueDate: "Jun 2023",
      expiryDate: "No expiration date",
      link: "https://www.codecademy.com/profiles/Ericalain1/certificates/2682884a0719474f96407efe432fdd87"
    },
    {
      title: "React for Beginners",
      issuer: "wesBos",
      issueDate: "May 2022",
      expiryDate: "No expiration date",
      link: "https://res.cloudinary.com/dky5oko1h/image/upload/v1659653641/eric-alain/resume/rfb-certificate.jpg"
    },
    {
      title: "Front End Libraries",
      issuer: "freeCodeCamp",
      issueDate: "Aug 2021",
      expiryDate: "No expiration date",
      link: "https://freecodecamp.org/certification/ericalain/front-end-libraries"
    },
    {
      title: "Data Visualization",
      issuer: "freeCodeCamp",
      issueDate: "Jul 2021",
      expiryDate: "No expiration date",
      link: "https://freecodecamp.org/certification/ericalain/data-visualization"
    },
    {
      title: "Learn the Command Line Course",
      issuer: "Codecademy",
      issueDate: "Jun 2021",
      expiryDate: "No expiration date",
      link: "https://www.codecademy.com/profiles/Ericalain1/certificates/5b32457b646caa5007c30975"
    },
    {
      title: "Web Development Career Path",
      issuer: "Codecademy",
      issueDate: "Jun 2021",
      expiryDate: "No expiration date",
      link: "https://www.codecademy.com/profiles/Ericalain1/certificates/5b32457b646caa5007c30975"
    },
    {
      title: "JavaScript Algorithms and Data Structures",
      issuer: "freeCodeCamp",
      issueDate: "May 2021",
      expiryDate: "No expiration date",
      link: "https://freecodecamp.org/certification/ericalain/javascript-algorithms-and-data-structures"
    },
    {
      title: "Learn Sass Course",
      issuer: "Codecademy",
      issueDate: "May 2021",
      expiryDate: "No expiration date",
      link: "https://www.codecademy.com/profiles/Ericalain1/certificates/eb1ffda40f347629dcef6de33d3f9741"
    },
    {
      title: "Build a Website with HTML, CSS, and Github Pages Skill Path",
      issuer: "Codecademy",
      issueDate: "Mar 2021",
      expiryDate: "No expiration date",
      link: "https://www.codecademy.com/profiles/Ericalain1/certificates/5cadfefe5f1de806e9704577"
    },
    {
      title: "Learn React Course",
      issuer: "Codecademy",
      issueDate: "Mar 2021",
      expiryDate: "No expiration date",
      link: "https://www.codecademy.com/profiles/Ericalain1/certificates/af00e5032d0a68cc84879983f5d8333b"
    },
    {
      title: "Learn CSS Course",
      issuer: "Codecademy",
      issueDate: "Sep 2020",
      expiryDate: "No expiration date",
      link: "https://www.codecademy.com/profiles/Ericalain1/certificates/9a5bb1fc45b4281af1fffec93b0aaf05"
    },
    {
      title: "Building Interactive JavaScript Websites Course",
      issuer: "Codecademy",
      issueDate: "Aug 2020",
      expiryDate: "No expiration date",
      link: "https://www.codecademy.com/profiles/Ericalain1/certificates/36ae898a1d1c8524815305b2d1d2ebab"
    },
    {
      title: "Learn Bash Scripting Course",
      issuer: "Codecademy",
      issueDate: "Aug 2020",
      expiryDate: "No expiration date",
      link: "https://www.codecademy.com/profiles/Ericalain1/certificates/37c55263a9f1b1f7603f7551c293ecbd"
    },
    {
      title: "Learn Git Course",
      issuer: "Codecademy",
      issueDate: "Aug 2020",
      expiryDate: "No expiration date",
      link: "https://www.codecademy.com/profiles/Ericalain1/certificates/a8ab218d5950c29861635cc0bf12fd13"
    },
    {
      title: "Learn HTML Course",
      issuer: "Codecademy",
      issueDate: "Aug 2020",
      expiryDate: "No expiration date",
      link: "https://www.codecademy.com/profiles/Ericalain1/certificates/9eb0741e5ebef1f9f58a53bfac67d3a7"
    },
    {
      title: "Learn JavaScript Course",
      issuer: "Codecademy",
      issueDate: "Aug 2020",
      expiryDate: "No expiration date",
      link: "https://www.codecademy.com/profiles/Ericalain1/certificates/705dcb15de0da4dd9d9fc4f3274b430e"
    }
  ]

  const determineImage = (string, title) => {
    //Helper function, cleans up logic and readability
    const filterByName = (regex, arr) => {
      return arr.filter(item => {
        return regex.test(item.secure_url)
      })[0].secure_url
    }

    switch (string) {
      case "Codecademy":
        return cloudinaryOptimize(filterByName(/Codecademy/, images), 150)
      case "freeCodeCamp":
        return cloudinaryOptimize(filterByName(/FreeCodeCamp/, images), 150)
      case "Microsoft":
        if (title.includes("AZ-900")) {
          return cloudinaryOptimize(filterByName(/AZ-900/, images), 150)
        } else {
          return
        }
      case "wesBos":
        return cloudinaryOptimize(filterByName(/wesbos-logo/, images), 150)
      default:
        return null
    }
  }

  const determineCredential = (title, issuer, link) => {
    if (
      issuer === "Codecademy" ||
      issuer === "freeCodeCamp" ||
      issuer === "Microsoft"
    )
      return (
        <a href={link} target="_blank" rel="noopener noreferrer">
          Show credential
        </a>
      )
    else if (issuer === "wesBos") {
      return (
        <div>
          <button
            onClick={() => {
              setModalData({
                ...modalData,
                [title]: {
                  show: true,
                  title: title,
                  link: cloudinaryOptimize(link, 1200)
                }
              })
            }}
            className="show-crendential-button"
          >
            Show credential
          </button>
          <ResumeModal
            title={modalData[title]?.title}
            link={modalData[title]?.link}
            show={modalData[title]?.show}
            handleClose={() => handleClose(title)}
          />
        </div>
      )
    } else return null
  }

  return (
    <Row className="resume-certifications mx-0 resume-content">
      {certificationData.map(
        ({ title, issuer, issueDate, expiryDate, link }, i) => (
          <Col xs="12" md="6" lg="4" key={i} className="mb-3">
            <Row className="align-items-md-start">
              <Col xs="3">
                <img
                  src={determineImage(issuer, title)}
                  alt={`${issuer} logo`}
                  className="w-100"
                />
              </Col>
              <Col xs="9">
                <div className="ms-1">
                  <h3 className="mb-1">{title}</h3>
                  <p className="mb-0">{issuer}</p>
                  <p className="mb-0 text-muted">
                    <small>{`Issued ${issueDate} - ${expiryDate}`}</small>
                  </p>
                  {determineCredential(title, issuer, link)}
                </div>
              </Col>
            </Row>
          </Col>
        )
      )}
    </Row>
  )
}

export const education = (
  <Col xs="12" className="mx-1">
    <h3>Algonquin College</h3>
    <p className="subtitle-resume">Aug 2015</p>
    <p>
      Advanced diploma in Business Administration - Specialization in General
      Administration
    </p>
  </Col>
)

export const references = (
  <Col xs="12" className="mx-1">
    <p>Extensive job history and references available upon request</p>
  </Col>
)
